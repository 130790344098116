<template>
  <b-modal 
    :id="id" 
    title="Gerenciar mat/med"  
    hide-footer
  >
    <div class="info">
      <p class="text-md">Você possui um ou mais “Template de kit” vinculado a este procedimento.</p>
      <p class="text-md">Selecione qual(is) template(s) você deseja utilizar?!</p>
    </div>
    <b-table-simple class="mt-3">
      <thead>
        <tr>
          <td>
            <span class="head">Template de kit</span>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="kitTemplate in kitTemplates" :key="kitTemplate?.id">
          <td>
            <b-row>
              <b-form-checkbox
                :checked="selectedKits.includes(kitTemplate?.id)"
                @change="handleChangeSelectedKits(kitTemplate?.id)"
                class="cursor-pointer"
              />
              <span class="text-md">
                {{ kitTemplate?.code }} - {{ kitTemplate?.name }}
              </span>
            </b-row>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
    <p class="text-sm">
      Para gerenciar o template e/ou itens individualmente, acesse a aba de ”Informações da cirurgia”, no campo "Dados da cirurgia".
    </p>
    <b-row class="d-flex justify-content-end mt-4">
      <b-col cols="4">
        <b-button class="close-btn mt-3" @click="closeModal()">
          Não, obrigado
        </b-button>
      </b-col>
      <b-col cols="3">
        <b-button variant="primary" class="mt-3" @click="emitAddKitTemplate()">
          Adicionar
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: 'SurgeryProductsTemplateModal',
  props: {
    id: {
      type: String,
      default: 'surgery-products-template-modal'
    },
    kitTemplates: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selectedKits: []
    }
  },
  methods: {
    closeModal(){
      this.$bvModal.hide(this.id)
    },
    handleChangeSelectedKits(kitId){
      if (this.selectedKits.includes(kitId))
        this.selectedKits = this.selectedKits.filter((id) => id !== kitId)
      else
        this.selectedKits.push(kitId)
    },
    emitAddKitTemplate(){
      this.$emit('add-kit-template', 
        this.kitTemplates.filter((kit) => this.selectedKits.includes(kit?.id))
      )
      this.closeModal()
    },
  }
}
</script>

<style lang="scss" src="@/assets/scss/table.scss" />

<style lang="scss" scoped>
.head {
  color: var(--neutral-700, #304388);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}

.text-sm {
  font-size: 14px;
  font-weight: 400;
  color: var(--type-placeholder, #8696AC);
}

.text-md {
  font-size: 16px;
  font-weight: 500;
  color: var(--type-active, #525C7A);
}

.cursor-pointer {
  cursor: pointer;
}

.close-btn {
  color: var(--states-error, #F63220);
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
</style>